<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button-group.tab-group
        b-button.mr-2(variant="primary" :class="{off: tab !== 1}" @click="changeTab(1)") 新聞標籤設定
        b-button.mr-2(variant="primary" :class="{off: tab !== 2}" @click="changeTab(2)") 影音標籤設定
    .col-lg-12()
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body v-if="tab === 1")
        b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 名稱
                    input.form-control(v-model="search.name", type="text")
                .col-lg-4  
                  .form-group.col
                    label.col-form-label(for="example-date") 開始時間
                    input#example-date.form-control(v-model="search.start_date", type="date", name="date")
                .col-lg-4  
                  .form-group.col
                    label.col-form-label(for="example-date") 結束時間
                    input#example-date.form-control(v-model="search.end_date" type="date", name="date")
              .row.d-flex.justify-content-end
                b-button.addbtn(variant="primary" class="ml-1 width-md", @click="getSiteTagList") 查詢
      //- 表格
      .card(v-if="tab === 1")
        .card-body
          .header-row.mb-3
            .header-title 新聞標籤列表
            b-button.width-md(
              variant="primary" 
              v-b-modal.modal-create-newstag
              @click="createNewsTag"
            )
              span 新增  
          //- Table
          .table-responsive.mb-0
            b-table(
              index="tagTable"
              :items="NewsTagList", 
              :fields="NewsTagDataFields", 
              responsive="sm", 
              :per-page="perPage", 
              :current-page="currentPage", 
              :sort-desc.sync="sortDesc", 
              :filter="filter", 
              :filter-included-fields="filterOn", 
              @filtered="onFiltered"
            )
              //- 操作
              template(v-slot:cell(edit)="row")
                b-button.table-btn.mr-1(
                  variant="primary" 
                  v-b-modal.modal-change-newstag
                  @click="changeNewsTag(row.item)"
                )
                  | 修改
                b-button.ml-0(variant="danger" size="md" @click="removeSiteTagItem('site',row.item.id)")
                  i.fe-trash-2 

          .row.mb-md-2
            .col-6
            .col-6
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")

        //- 處理建立新聞標籤
        b-modal#modal-create-newstag(
          title="建立新聞標籤", 
          title-class="font-18", 
          body-class="p-4" 
          centered
          @ok="saveNewsTag"
        )
          .row
            .col-md-12
              .form-group
                input.form-control(type="text", placeholder="請輸入標籤名稱",v-model="setNewsTag.name")

        //- 處理修改新聞標籤
        b-modal#modal-change-newstag(
          title="修改新聞標籤", 
          title-class="font-18", 
          body-class="p-4" 
          centered
          @ok="saveNewsTag"
        )
          .row
            .col-md-12
              .form-group
                input.form-control(type="text", placeholder="請輸入標籤名稱",v-model="setNewsTag.name")

      //- 表格
      .card(v-if="tab === 2")
        .card-body
          .header-row.mb-3
            .header-title 影音標籤列表
            b-button.width-md(
              variant="primary" 
              v-b-modal.modal-create-videotag
              @click="createVideoTag"
            )
              span 新增  
            //- b-button.width-md.ml-2(variant="primary")
              span 排序  
          //- Table
          .table-responsive.mb-0
            b-table(
              index="tagTable"
              :items="VideoTagList", 
              :fields="VideoTagDataFields", 
              responsive="sm", 
              :per-page="perPage", 
              :current-page="currentPage", 
              :sort-desc.sync="sortDesc", 
              :filter="filter", 
              :filter-included-fields="filterOn", 
              @filtered="onFiltered"
            )
              //- 操作
              template(v-slot:cell(ytlist_uri)="row")
                //- span {{ row.item.ytlist_uri }}
                a(:href="row.item.ytlist_uri" target="new") 
                  | 播放清單: {{  $root.common.getYoutubePlaylistIdByUri( row.item.ytlist_uri )  }}

              //- 操作
              template(v-slot:cell(edit)="row")
                b-button.table-btn.mr-1(
                  variant="primary" 
                  v-b-modal.modal-change-videotag
                  @click="changeVideoTag(row.item)"
                )
                  | 修改
                b-button.ml-0(variant="danger" size="md" @click="removeSiteTagItem('video',row.item.id)")
                  i.fe-trash-2 

          .row.mb-md-2
            .col-6
            .col-6
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")

        //- 處理建立影音標籤
        b-modal#modal-create-videotag(
          title="建立影音標籤", 
          title-class="font-18", 
          body-class="p-4" 
          centered
          @ok="saveVideoTag"
        )
          .row
            .col-md-12
              .form-group
                label 影音標籤名稱
                input.form-control(type="text", placeholder="請輸入標籤名稱",v-model="setVideoTag.name")
            .col-md-12.mb-3
              b-form-checkbox#checkbox-1(v-model="setVideoTag.on_fix", name="checkbox", value="accepted" unchecked-value="not_accepted") 此為政治分類固定在第2排序
            .col-md-12
              .form-group
                label Youtube網址
                input.form-control(type="text", placeholder="請輸入YT對應播放清單網址(請勿輸入短網址)...",v-model="setVideoTag.ytlist_uri")

        //- 處理建立修改標籤
        b-modal#modal-change-videotag(
          title="修改影音標籤", 
          title-class="font-18", 
          body-class="p-4" 
          centered
          @ok="saveVideoTag"
        )
          .row
            .col-md-12
              .form-group
                label 排序位置(數值越小越前面)
                input.form-control(type="text", placeholder="請輸入標籤名稱",v-model="setVideoTag.sort")
            .col-md-12
              .form-group
                label 影音標籤名稱
                input.form-control(type="text", placeholder="請輸入標籤名稱",v-model="setVideoTag.name")
            .col-md-12.mb-3
              b-form-checkbox#checkbox-1(v-model="setVideoTag.on_fix", name="checkbox", value="accepted" unchecked-value="not_accepted") 此為政治分類固定在第2排序
            .col-md-12
              .form-group
                label Youtube網址
                input.form-control(type="text", placeholder="請輸入YT對應播放清單網址(請勿輸入短網址)...",v-model="setVideoTag.ytlist_uri")

</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";


/**
 * Starter component
 */
export default {
  name: "SiteTagsSettingList",
  data () {
    return {
      tab: 1,
      title: "Tag 設定",
      items: [
        {
          text: "上稿管理",
          href: "/",
        },
        {
          text: "Tag 設定",
          active: true,
        }
      ],
      search: {
        name: '',
        start_date: null,
        end_date: null,
      },
      visible: true,
      setNewsTag: { name: '' },
      setVideoTag: { name: '', ytlist_uri: '', on_fix: false },
      NewsTagList: [],
      VideoTagList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      NewsTagDataFields: [
        { key: 'name', label: "標籤名稱", class: 'width-auto center' },
        { key: 'updated_at', label: "建立時間", class: 'width-auto center' },
        { key: "edit", label: "操作", class: 'width-auto center' },
      ],
      VideoTagDataFields: [
        { key: 'name', label: "標籤名稱", class: 'width-auto center' },
        { key: 'updated_at', label: "更新時間", class: 'width-auto center' },
        { key: 'ytlist_uri', label: "對應YT播放清單連結", class: 'width-auto center' },
        { key: 'sort', label: "排序", class: 'width-auto center' },
        { key: "edit", label: "操作", class: 'width-auto center' },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows () {
      if (this.tab === 1) {
        return this.NewsTagList.length;
      }
      if (this.tab === 2) {
        return this.VideoTagList.length;
      }

    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  watch: {
    tab (_tab) {
      this.currentPage = 1
      this.getSiteTagList()
    }
  },
  created () {
    this.getSiteTagList()
  },
  methods: {
    changeTab (_status) {
      this.tab = _status
    },
    // 獲得標籤清單
    getSiteTagList () {
      if (this.tab == 1) {
        this.getSiteNewsTagList()
      }
      if (this.tab == 2) {
        this.getSiteVideoTagList()
      }
    },
    // 獲得新聞標籤清單
    getSiteNewsTagList () {
      let vm = this
      let search = this.search
      search.page = this.currentPage
      this.$root.apis.getSiteNewsTagList(search,
        function (_response) {
          let response = _response.body.data
          vm.NewsTagList = response.tags
          vm.totalRows = response.total
          vm.perPage = response.per_page
        })
    },
    // 獲得影音標籤清單
    getSiteVideoTagList () {
      let vm = this
      this.$root.apis.getSiteVideoTagList({
        page: this.currentPage,
      }, function (_response) {
        let response = _response.body.data
        vm.VideoTagList = response.tags
        vm.totalRows = response.total
        vm.perPage = response.per_page
      })
    },
    createNewsTag () {
      this.setNewsTag = { name: '' }
    },
    changeNewsTag (_item) {
      console.log('=> 修改新聞標籤項目:', _item)
      this.setNewsTag = _item
    },
    createVideoTag () {
      this.setVideoTag = { name: '', ytlist_uri: '', on_fix: false }
    },
    changeVideoTag (_item) {
      console.log('=> 修改影音標籤項目:', _item)
      this.setVideoTag = _item
    },
    saveNewsTag () {
      let model = 'create'
      if (this.setNewsTag.id) {
        model = 'update'
      }
      console.log('=> 新聞標籤使用存取模式:', model)

      if (model === 'update') {
        return this.putNewsTag()
      }

      if (model === 'create') {
        return this.createNewsTagItem()
      }
    },
    saveVideoTag () {
      let vm = this
      let model = 'create'
      if (this.setVideoTag.id) {
        model = 'update'
      }
      console.log('=> 影音標籤使用存取模式:', model)

      if (model === 'update') {
        return this.putVideoTag()
      }

      if (model === 'create') {
        return this.createVideoTagItem()
      }
    },
    createNewsTagItem () {
      let vm = this
      this.$root.apis.createSiteNewsTag({
        name: this.setNewsTag.name
      }, function (_response) {
        vm.getSiteTagList()
        vm.$root.common.showSingleNotify("新聞標籤建立成功")
      }, function (_error) {

      })
    },
    putNewsTag () {
      let vm = this
      this.$root.apis.putSiteNewsTag(this.setNewsTag.id, {
        name: this.setNewsTag.name
      }, function (_response) {
        vm.getSiteTagList()
        vm.$root.common.showSingleNotify("新聞標籤更新成功")
      }, function (_error) {

      })
    },
    createVideoTagItem () {
      let vm = this
      this.$root.apis.createSiteVideoTag({
        name: this.setVideoTag.name,
        on_fix: this.setVideoTag.on_fix,
        youtube_playlist_uri: this.setVideoTag.ytlist_uri,
        sort: this.setVideoTag.sort
      }, function (_response) {
        vm.getSiteTagList()
        vm.$root.common.showSingleNotify("影音標籤建立成功")
      }, function (_error) {

      })
    },
    putVideoTag () {
      let vm = this
      this.$root.apis.putSiteVideoTag(this.setVideoTag.id, {
        name: this.setVideoTag.name,
        on_fix: this.setVideoTag.on_fix,
        youtube_playlist_uri: this.setVideoTag.ytlist_uri,
        sort: this.setVideoTag.sort
      }, function (_response) {
        vm.getSiteTagList()
        vm.$root.common.showSingleNotify("影音標籤更新成功")
      }, function (_error) {

      })
    },
    // 請求移除標籤
    removeSiteTagItem (_type, _itemId) {
      let vm = this
      this.$root.common.confirmAction("移除標籤", "確定要移除這個標籤項目嗎?", "確定移除", "先不要", function () {
        vm.$root.apis.removeSiteTag(_type, _itemId, function () {
          vm.getSiteTagList()
          vm.$root.common.showSingleNotify("標籤移除成功")
        })
      })
    },
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
</style>
